import { createRouter, createWebHistory } from '@ionic/vue-router'
import maps2 from '../views/maps2.vue'
import mapsBarras from '../views/mapsBarras.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/maps2',
    name: 'maps2',
    component: maps2
  },
  {
    path: '/mapsBarras',
    name: 'mapsBarras',
    component: mapsBarras
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
