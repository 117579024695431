<template>
  <div class='row'>
    <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    text-color="#fff"
    active-text-color="#008AFF"
    @select="handleSelect"
    :collapse="false">
      <el-sub-menu index="10">
        <template #title>Menu</template>
        <el-menu-item index="10-1" background-color="#545c64"><router-link to="/">Login</router-link></el-menu-item>
        <el-menu-item index="10-2"><router-link to="/maps2">Mapa Combustible</router-link></el-menu-item>
        <el-menu-item index="10-3"><router-link to="/mapsBarras">Combustible por Municipio</router-link></el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="11">
        <template #title>Tableros</template>
        <el-menu-item index="11-4"><a href="https://lookerstudio.google.com/u/0/reporting/1J4EeiltqDbnNiUZvAQpVAcZxYFQ6DOjm/page/F4PX" target="_blank" rel="noreferrer noopener"> Volumen por zona COMBPC</a></el-menu-item>
        <el-menu-item index="11-6"><a href="https://lookerstudio.google.com/u/0/reporting/1sI8UFhnTTQRYQFGGPtbjO-eraGhcAg6f/page/F4PX" target="_blank" rel="noreferrer noopener"> Volumen X Vendedor COMBPC </a></el-menu-item>
        <el-menu-item index="11-6"><a href="https://lookerstudio.google.com/u/0/reporting/1eYZPmoRVU3BeBO1BOeAcJgCRpnZuHqss/page/3n8Y" target="_blank" rel="noreferrer noopener"> Comparacion distribuidores COMBPC </a></el-menu-item>
        <el-menu-item index="11-4"><a href="https://datastudio.google.com/u/0/reporting/1mndc_VJFCd6Tp40hT2wNaTkXxpguMb34/page/F4PX" target="_blank" rel="noreferrer noopener"> Volumen por zona PETROCOM</a></el-menu-item>
        <el-menu-item index="11-5"><a href="https://lookerstudio.google.com/u/0/reporting/1vnvO79kaU-z0gr-XmppOhupJDw270aEp/page/F4PX" target="_blank" rel="noreferrer noopener"> Volumen X Vendedor PETROCOM </a></el-menu-item>
        <el-menu-item index="11-6"><a href="https://lookerstudio.google.com/u/0/reporting/1qZ7emaKmj3uKsohi35FjR-AvOUuZUbH_/page/3n8Y" target="_blank" rel="noreferrer noopener"> Comparacion distribuidores PETROCOM </a></el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
  <div class="line"></div>
  <router-view/>
</template>
<script>
export default {
  data () {
    return { activeIndex: '1' }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #8d8c8c; */
  color: #bbbfc0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.4);
  color: rgb(212, 208, 208);
}
td {
  color: rgb(212, 208, 208);
}
tr.blue th {
  background: #008AFF;
  color: rgb(212, 208, 208);
}
tr.gray td {
  background: gray;
  position: sticky;
  color: rgb(212, 208, 208);
}
</style>
