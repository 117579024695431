<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='updateChart'>
    <el-select
      v-model="this.getToken.empresa"
      filterable
      @change="this.getData()"
      clearable
      :disabled="false"
      placeholder="Empresa"
    >
      <el-option
        v-for="item in empresas"
        :key="item.nombre"
        :label="item.nombre"
        :value="item.nombre"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="rubro"
      filterable
      clearable
      :disabled="true"
      placeholder="Rubro"
    >
      <el-option
        v-for="item in rubros"
        :key="item.nombre"
        :label="item.nombre"
        :value="item.nombre"
      >
      </el-option>
    </el-select>
    <div id='map' ref='map'>
    </div>
  </form>
  Ultima actualizacion: {{this.updated}}
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'

export default {
  components: {
    back
  },
  setup () {
  },
  extends: shared,
  created: function () {
    // this.setEmpresa()
    // if (this.getArea.empresa == null) {
    //   if (this.getArea.area === 'AGRT') this.setEmpresa('PETROCOM')
    //   if (this.getArea.area === 'AGRB') this.setEmpresa('COMBPC')
    // }
  },
  mounted: function () {
    this.refresh()
    window.setInterval(() => {
      console.log('schedule#check ' + new Date())
      this.refresh()
    }, 1 * 60 * 1000)
    this.updateChart()
  },
  data: function () {
    return {
      empresas: [{
        nombre: 'COMBPC'
      }, {
        nombre: 'PETROCOM'
      }
      ],
      // empresa: '',
      rubros: [{
        nombre: 'lubricantes'
      },
      {
        nombre: 'combustibles'
      }
      ],
      rubro: 'combustibles',
      map: null,
      updated: '',
      loading: '',
      isLoaded: false,
      chartOptions: [],
      cicloChartOptions: [],
      data: [],
      date1: null,
      date2: null,
      value1: '',
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    refresh () {
      if (this.diff_minutes(this.strToDate(JSON.parse(localStorage.getItem('mapupdated'))), new Date()) > 9 || JSON.parse(localStorage.getItem('mapupdated')) === null) {
        console.log('schedule#run ' + new Date())
        this.getData()
        this.updated = JSON.parse(localStorage.getItem('mapupdated'))
      }
    },
    strToDate (str) { // recibe string con formato dd/mm/yyyy
      var lret
      if (str) {
        var day = str.split('/')[0]
        var month = str.split('/')[1]
        var year = str.split('/')[2].substring(0, 4)
        var aelse = str.split('/')[2].substring(5, 100)
        lret = new Date(year + '-' + month + '-' + day + ' ' + aelse)
      } else {
        lret = new Date()
      }
      return lret
    },
    diff_minutes (dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000
      diff /= 60
      return Math.abs(Math.round(diff))
    },
    getData: function (e) {
      var self = this
      // self.openFSL(self)
      // si paso mas de 1 hora
      const url = 'http://' + config.ip + '/bidi/maps/clientes/params;localidad=;empresa=' + this.getToken.empresa
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          var today = new Date()
          self.updated = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('00' + today.getMinutes()).substring(('00' + today.getMinutes()).length - 2)
          localStorage.setItem('locations', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          localStorage.setItem('mapupdated', JSON.stringify(self.updated).split('T00:00:00Z[-03:00]').join(''))
          self.updateChart()
          // self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL(self)
        })
        // var today = new Date()
        // self.updated = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('00' + today.getMinutes()).substring(('00' + today.getMinutes()).length - 2)
    },
    errorMsg (val) {
      this.$message({
        message: val,
        type: 'error'
      })
    },
    updateChart () {
      const self = this
      this.updated = JSON.parse(localStorage.getItem('mapupdated'))
      self.locations = JSON.parse(localStorage.getItem('locations'))
      if (self.locations) {
        this.map = new window.google.maps.Map(this.$refs.map, {
          center: { lat: -37.3288, lng: -59.1382 },
          zoom: 20
        })
        var marker, i
        for (i = 0; i < this.locations.length; i++) {
          marker = new window.google.maps.Marker({
            icon: this.locations[i].color.toLowerCase() === 'verde' ? 'green.png' : this.locations[i].color.toLowerCase() === 'amarillo' ? 'yellow.png' : 'red.png',
            position: new window.google.maps.LatLng(this.locations[i].latitud, this.locations[i].longitud),
            label: '',
            title: 'Cuenta: ' + this.locations[i].cuenta + ' ' + this.locations[i].nombre +
                  '\nMunicipio: ' + this.locations[i].localidad +
                  '\nActividad: ' + this.locations[i].actividad +
                  '\nVol Actual: ' + this.locations[i].volumen +
                  '\nVol Ultimos 12 meses: ' + this.locations[i].volumen12 +
                  '\nFec Ultima Compra: ' + this.locations[i].fecultcom +
                  '\nVol Ultima Compra: ' + this.locations[i].volultcom,
            map: this.map
          })

          window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
              router.push('/mapsBarras?id=' + marker.getTitle())
            }
          })(marker, i))
        }
        new window.google.maps.KmlLayer('http://ticket.grupopenna.com.ar/AXION.kmz', {
          suppressInfoWindows: false,
          preserveViewport: false,
          map: this.map
        })
      }
    }
  }
}
</script>
<style scoped>
  #map {
    height: 600px;
    background: gray
  }
</style>
