<template>
  <div class="row">
    <div class="col-md-10">
    </div>
    <div class="col-md-2">
      <el-button-group>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="back()" plain></el-button>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="forward()" plain></el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import router from '../router'
export default {
  name: 'back',
  methods: {
    back () {
      router.go(-1)
    },
    forward () {
      router.go(1)
    }
  }
}
</script>
