import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state'

export default createStore({
  state: {
    user: null,
    token: null,
    area: null,
    empresa: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.token = token
    },
    setArea (state, area) {
      state.area = area
    },
    setEmpresa (state, val) {
      state.empresa = val
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isLoggedIn (state) {
      return !!state.token
    },
    getToken (token) {
      return token
    },
    getArea (area) {
      return area
    },
    getEmpresa (empresa) {
      return empresa
    },
    errorMsg (val) {
      this.$message({
        message: val,
        type: 'error'
      })
    }
  },
  plugins: [
    createMultiTabState()
  ]
})
