import { mapMutations, mapGetters } from 'vuex'
export default {
  data: function () {
    return {
      impIndex: 0
    }
  },
  computed: {
    ...mapGetters(['getArea'])
  },
  methods: {
    ...mapMutations(['setEmpresa']),
    // getEmpresa () {
    //   return this.getArea.empresa
    // },
    // setEmpresa () {
    //   if (this.getArea.empresa == null) {
    //     if (this.getArea.area === 'AGRT') this.setEmpresa('PETROCOM')
    //     if (this.getArea.area === 'AGRB') this.setEmpresa('COMBPC')
    //   }
    // },
    isNumber (evt) {
      evt = evt || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    openFSL (self) {
      self.loading = self.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL (self) {
      self.loading.close()
    },
    bar () {
      alert('bar')
    },
    successMsg (val) {
      this.$message({
        message: val,
        type: 'success'
      })
    },
    errorMsg (val) {
      this.$message({
        message: 'mensaje de error: ' + val,
        type: 'error'
      })
    },
    // mostrarImputacion (self, fecha, profesional, texto) {
    //   self.dialogVisible = true
    //   self.dialogo = 'FECHA:\n' + fecha + '\n\n'
    //   self.dialogo = self.dialogo + 'PROFESIONAL:\n' + profesional + '\n\n'
    //   self.dialogo = self.dialogo + 'TEXTO:\n' + texto
    // },
    // mostrarImputacionV2 (self, item, index) {
    //   this.impIndex = index
    //   self.dialogVisible = true
    //   self.dialogo = 'TICKET:\n' + item[index].ticket + ' - ' + item[index].titulo + '\n\n'
    //   self.dialogo = self.dialogo + 'FECHA:\n' + item[index].fecha + '\n\n'
    //   self.dialogo = self.dialogo + 'PROFESIONAL:\n' + item[index].profesional + '\n\n'
    //   self.dialogo = self.dialogo + 'HS:\n' + item[index].horas + '\n\n'
    //   self.dialogo = self.dialogo + 'TEXTO:\n' + item[index].texto
    // },
    numberFormat (val, digit) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      })
      return formatter.format(val)
    }
  }
}
