<template>
  <div v-if='isLoggedIn' class='container-fluid'>
    <back />
    <!-- <form class="form-inline" role="form" @submit.prevent="getVolume_C"> -->
    <form class="form-inline" role="form" @submit.prevent="getData">
      <br />
      <div class="row">
        <div class="col-md-2">
          <el-select
            v-model="cliente"
            filterable
            clearable
            placeholder="Cliente"
          >
            <el-option
              v-for="item in clientes"
              :key="item.cliente[0]"
              :label="item.cliente[0] + '-' + item.cliente[1]"
              :value="item.cliente[0]"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-1">
          <el-select
            v-model="vendedor"
            filterable
            clearable
            placeholder="Vendedor"
          >
            <el-option
              v-for="item in vendedores"
              :key="item.vendedor[0]"
              :label="item.vendedor[0] + '-' + item.vendedor[1]"
              :value="item.vendedor[0]"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-1">
          <el-select
            v-model="localidad"
            filterable
            clearable
            placeholder="Municipio"
          >
            <el-option
              v-for="item in localidades"
              :key="item.localidad"
              :label="item.localidad"
              :value="item.localidad"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-1">
          <el-select
            v-model="rubro"
            filterable
            clearable
            :disabled="true"
            placeholder="Rubro"
          >
            <el-option
              v-for="item in rubros"
              :key="item.nombre"
              :label="item.nombre"
              :value="item.nombre"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-4">
          <span style="padding-right: 10px">Rango de fechas</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            unlink-panels
            range-separator="|"
            start-placeholder="Desde"
            end-placeholder="Hasta"
          >
          </el-date-picker>
        </div>
        <div class="col-md-1">
          <button type="submit" class="btn btn-primary mb-2">Buscar</button>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-4">
      </div>
        <div class="col-md-2">
          <el-card shadow="always">
            <!-- <H7>#% Marcacion </H7> -->
            <div class="row">
               <div class="col-md-12">
                <span class="percentage-label" style="color: #bbbfc0;"><strong> {{localidadbuscada}}</strong></span>
              </div>
              <div class="col-md-12">
                <span class="percentage-label" style="color: #bbbfc0;"><strong> {{clientebuscado}}</strong></span>
              </div>
              <div class="col-md-12">
                <span class="percentage-label" style="color: #bbbfc0;"><strong> {{vendedorbuscado}}</strong></span>
              </div>
              <div class="col-md-12">
                <span class="percentage-label" style="color: #bbbfc0;"> {{periodoant}} </span>
                 </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span class="percentage-label" style="color: #bbbfc0;"><strong> {{GMant}}% Marcacion</strong></span>
                  </div>
            </div>
               <div class="col-md-12">
              <div class="percentage-label" style="color: #bbbfc0;"><strong> {{volant}} m3 </strong></div>
            </div>
           </el-card>
        </div>
        <div class="col-md-2">
          <el-card shadow="always">
            <div class="col-md-12">
              <span class="percentage-label" style="color: #00adff;"><strong> {{localidadbuscada}}</strong></span>
            </div>
            <div class="col-md-12">
              <span class="percentage-label" style="color: #00adff;"><strong> {{clientebuscado}}</strong></span>
            </div>
            <div class="col-md-12">
              <span class="percentage-label" style="color: #00adff;"><strong> {{vendedorbuscado}}</strong></span>
            </div>
            <div class="col-md-12">
              <span class="percentage-label" style="color: #00adff;">{{periodoact}} </span>
            </div>
            <div class="col-md-12">
              <span class="percentage-label" style="color: #00adff;"><strong> {{GMact}}% Marcacion</strong></span>
            </div>
            <div class="col-md-12">
              <div class="percentage-label" style="color: #00adff;"><strong> {{volact}} m3 </strong></div>
            </div>
           </el-card>
        </div>
        <div class="col-md-4">
        </div>
      </div>
      <br/>
      <div class="row">
        <el-collapse v-model="activeHeaderName">
          <el-collapse-item :title= titulo_1  name="1">
            <apexchart
              type="bar"
              :options="chartOptions_p"
              :series="dataZonas_p"
              v-if="isLoaded"
              height="400"
            ></apexchart>
          </el-collapse-item>
          <el-collapse-item :title= titulo_2 name="2">
            <apexchart
              type="bar"
              :options="chartOptions"
              :series="dataZonas_c"
              v-if="isLoaded"
              height="400"
            ></apexchart>
          </el-collapse-item>
          <el-collapse-item :title= titulo_3 name="3">
            <apexchart
              type="bar"
              :options="chartOptionsGM"
              :series="dataGM"
              v-if="isLoaded"
              height="400"
            ></apexchart>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-4">Ultima actualizacion: {{ updated }}</div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
export default {
  components: {
    back
  },
  extends: shared,
  beforeMount: function () {
  },
  mounted: async function () {
    this.getClientes()
    this.getMunicipios()
    this.getVendedores()
    this.updated = JSON.parse(localStorage.getItem('mapupdated'))
    window.setInterval(() => {
      this.updated = JSON.parse(localStorage.getItem('mapupdated'))
    }, 1 * 60 * 1000)
    if (!this.isLoggedIn) {
      router.push('/')
    } else {
      // this.getMunicipios()
      // this.getClientes()
      // this.getVendedores()
      if (this.$route.query.id) {
        let result = this.$route.query.id.split('\n')
        result = result[0].toString().split(':')
        this.cliente = result[1].trim().substring(0, 6)
        // alert(this.cliente)
        var self = this
        setTimeout(async function () {
          await self.getData()
        }, 1000)
      }
    }
  },
  data: function () {
    return {
      rubros: [{
        nombre: 'lubricantes'
      },
      {
        nombre: 'combustibles'
      }
      ],
      rubro: 'combustibles',
      clientebuscado: '',
      vendedorbuscado: '',
      titulo_1: '',
      titulo_2: '',
      titulo_3: '',
      anioant: 2021,
      anioact: 2022,
      periodoant: 2021,
      periodoact: 2022,
      volact: 0,
      volant: 0,
      GMact: 0,
      GMant: 0,
      perMG: 100,
      activeHeaderName: 1,
      localidades: [],
      localidad: '',
      clientes: [],
      cliente: '',
      vendedores: [],
      vendedor: '',
      updated: '',
      loading: '',
      isLoaded: false,
      chartOptions_p: [],
      chartOptions: [],
      labels: [],
      datasets: [],
      date1: null,
      date2: null,
      value1: [
        new Date(new Date().getFullYear() + '-01-01T03:00:00Z'),
        new Date()
      ],
      search: '',
      searchCargas: '',
      dispo: [],
      dataZonas_p: [],
      dataZonas_c: [],
      dataGM: [],
      zonas: [],
      grossmargin: [],
      zonas_p: [],
      cargas: [],
      ciclos: [],
      sinergia: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [
        {
          text: '1 Week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          }
        },
        {
          text: '2 Weeks',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
            return [start, end]
          }
        },
        {
          text: '1 Month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          }
        },
        {
          text: '3 Months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          }
        }
      ]
    }
  },
  watch: {
    anioant (newVal) {
      this.titulo_1 = 'Volumen ' + this.anioact + ' vs Promedio ' + this.anioant
      this.titulo_2 = 'Volumen ' + this.anioact + ' vs ' + this.anioant
      this.titulo_3 = '% Marcacion ' + this.anioact + ' vs ' + this.anioant
    },
    anioact (newVal) {
      this.titulo_1 = 'Volumen ' + this.anioact + ' vs Promedio ' + this.anioant
      this.titulo_2 = 'Volumen ' + this.anioact + ' vs ' + this.anioant
      this.titulo_3 = '% Marcacion ' + this.anioact + ' vs ' + this.anioant
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredImpu () {
      return this.impu.filter(this.filterByProf)
    },
    filteredCargas () {
      return this.cargas.filter(this.filterByNothing)
    },
    filteredCiclos () {
      return this.ciclos.filter(this.filterByNothing)
    }
  },
  methods: {
    async getData () {
      this.openFSL()
      await this.getVolume_C()
      await this.getVolume_P()
      await this.getGrossMargin()
      this.isLoaded = true
      this.closeFSL()
      this.updateChart()
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getVolume_C: function (e) {
      var self = this
      // self.openFSL()
      const url =
        'http://' + config.ip + '/bidi/maps/m3xlocalidad/params;cliente=' + self.cliente + ';vendedor=' + self.vendedor + ';localidad=' +
        self.localidad +
        ';desde=' +
        self.strToDate(self.value1[0]) +
        ';hasta=' +
        self.strToDate(self.value1[1]) +
        ';opciones=C' +
        ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      return axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'zonas',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.zonas = JSON.parse(localStorage.getItem('zonas'))
          // self.updateChart()
          // self.closeFSL()
          // self.isLoaded = true
        })
        .catch(function (error) {
          // self.closeFSL()
          console.log(error)
        })
      // self.getVolume_P()
      // self.getGrossMargin()
    },
    getVolume_P: function (e) {
      var self = this
      // self.openFSL()
      const url =
        'http://' + config.ip + '/bidi/maps/m3xlocalidad/params;cliente=' + self.cliente + ';vendedor=' + self.vendedor + ';localidad=' +
        self.localidad +
        ';desde=' +
        self.strToDate(self.value1[0]) +
        ';hasta=' +
        self.strToDate(self.value1[1]) +
        ';opciones=P' +
        ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      return axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'zonas_p',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.zonas_p = JSON.parse(localStorage.getItem('zonas_p'))
          // self.updateChart()
          // self.closeFSL()
          // self.isLoaded = true
        })
        .catch(function (error) {
          // self.closeFSL()
          console.log(error)
        })
    },
    getGrossMargin: function (e) {
      var self = this
      // self.openFSL()
      const url =
        'http://' + config.ip + '/bidi/maps/grossmargin/params;cliente=' + self.cliente + ';vendedor=' + self.vendedor + ';localidad=' +
        self.localidad +
        ';desde=' +
        self.strToDate(self.value1[0]) +
        ';hasta=' +
        self.strToDate(self.value1[1]) +
        ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      return axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'grossmargin',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.grossmargin = JSON.parse(localStorage.getItem('grossmargin'))
          // self.updateChart()
          // self.closeFSL()
          // self.isLoaded = true
        })
        .catch(function (error) {
          // self.closeFSL()
          console.log(error)
        })
    },
    getMunicipios: function (e) {
      var self = this
      // self.openFSL()
      console.log(self.value1[0])
      const url =
        'http://' + config.ip + '/bidi/maps/localidades/params' + ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'localidades',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.localidades = JSON.parse(localStorage.getItem('localidades'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getClientes: async function (e) {
      var self = this
      // self.openFSL()
      console.log(self.value1[0])
      const url =
        'http://' + config.ip + '/bidi/maps/clientesMaestro/params' + ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      return axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'clientes',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.clientes = JSON.parse(localStorage.getItem('clientes'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getVendedores: function (e) {
      var self = this
      // self.openFSL()
      console.log(self.value1[0])
      const url =
        'http://' + config.ip + '/bidi/maps/vendedores/params' + ';empresa=' + this.getToken.empresa
      axios.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response.status === 401) {
            router.push('/')
          }
          return Promise.reject(error)
        }
      )
      axios
        .get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
        .then(function (response) {
          localStorage.setItem(
            'vendedores',
            JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join('')
          )
          self.vendedores = JSON.parse(localStorage.getItem('vendedores'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    updateChart () {
      console.log('barras#updateChart')
      var self = this
      var yGoalsZonaP = []
      var yZonaP = []
      var xZonaP = []
      var yGoalsZonaC = []
      var yZonaC = []
      var xZonaC = []
      var yGoalsGM = []
      var yGM = []
      var xGM = []
      let refAnt = false
      this.grossmargin.forEach(function (item) {
        if (item.periodo.indexOf('|') < 0) {
          if (item) {
            yGM.push(Math.round((item.porcentaje) * 100) / 100)
            yGoalsGM.push(Math.round((item.referencia) * 100) / 100)
            xGM.push(item.periodo)
          }
        }
      })
      this.localidadbuscada = this.zonas[2].localidad
      self.clientebuscado = ''
      this.clientes.forEach(function (item) {
        if (item.cliente[0] === self.cliente) {
          if (item) {
            self.clientebuscado = item.cliente[0] + '-' + item.cliente[1]
          }
        }
      })
      self.vendedorbuscado = ''
      this.vendedores.forEach(function (item) {
        if (item.vendedor[0] === self.vendedor) {
          if (item) {
            self.vendedorbuscado = item.vendedor[0] + '-' + item.vendedor[1]
          }
        }
      })
      console.log(this.clientebuscado)
      this.anioant = this.zonas[0].periodo.split('|')[0]
      this.anioact = this.zonas[0].periodo.split('|')[1]
      this.periodoant = this.zonas[1].periodo.split('|')[0]
      this.periodoact = this.zonas[1].periodo.split('|')[1]
      this.volact = Math.round((this.zonas[1].porcentaje) * 100) / 100
      this.volant = Math.round((this.zonas[1].referencia) * 100) / 100
      this.GMact = Math.round((this.grossmargin[1].porcentaje) * 100) / 100
      this.GMant = Math.round((this.grossmargin[1].referencia) * 100) / 100
      this.zonas.forEach(function (item) {
        if (item.periodo.indexOf('|') < 0) {
          if (item) {
            yZonaC.push(Math.round((item.porcentaje) * 100) / 100)
            yGoalsZonaC.push(Math.round((item.referencia) * 100) / 100)
            xZonaC.push(item.periodo)
          }
        }
      })
      this.zonas_p.forEach(function (item) {
        if (item) {
          yZonaP.push(Math.round((item.porcentaje) * 100) / 100)
          if (item.referencia !== 0 && !refAnt) {
            yGoalsZonaP.push(Math.round((item.referencia) * 100) / 100)
            refAnt = true
          } else {
            yGoalsZonaP.push(null)
          }
          xZonaP.push(item.periodo)
        }
      })
      self.dataZonas_p = [
        {
          data: yGoalsZonaP,
          type: 'column',
          name: this.periodoant
        },
        {
          data: yZonaP,
          type: 'column',
          name: this.periodoact
        }
      ]

      self.dataZonas_c = [
        {
          data: yGoalsZonaC,
          type: 'column',
          name: this.periodoant
        },
        {
          data: yZonaC,
          type: 'column',
          name: this.periodoact
        }
      ]

      self.dataGM = [
        {
          data: yGoalsGM,
          type: 'column',
          name: this.periodoant
        },
        {
          data: yGM,
          type: 'column',
          name: this.periodoact
        }
      ]

      self.chartOptionsGM = {
        colors: ['#bbbfc0', '#00adff'],
        xaxis: {
          categories: xGM,
          rotate: -45,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }

      self.chartOptions = {
        colors: ['#bbbfc0', '#00adff'],
        xaxis: {
          categories: xZonaC,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }

      self.chartOptions_p = {
        colors: ['#bbbfc0', '#00adff'],
        xaxis: {
          categories: xZonaP,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
    },
    errorMsg (val) {
      this.$message({
        message: val,
        type: 'error'
      })
    },
    sum () {
      var res = 0
      this.filteredImpu.forEach(function (item) {
        res = res + item.km
      })
      return this.numberFormat(Math.round(res * 100) / 100)
    },
    sumCargas () {
      var res = 0
      this.filteredCargas.forEach(function (item) {
        res = res + item.cargas
      })
      return this.numberFormat(Math.round(res * 100) / 100)
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      )
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      // return true
      return (
        (imp.unidad
          .toString()
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) > -1 &&
          this.unidadF) ||
        (imp.chofer
          .toString()
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) > -1 &&
          this.choferF)
      )
    },
    filterByNothing (imp) {
      return true
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    },
    strToHour (str) {
      var ret = str.substring(0, 2) + ':'
      ret = ret + str.substring(2, 4)
      return ret
    },
    numberFormat (val) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0
      })
      return formatter.format(val)
    }
  }
}
</script>
<style type='text/css'>
.apexcharts-yaxis-label {
  fill: #bbbfc0
}
.apexcharts-tooltip-text-label {
  color: #0f0f0f
}
.apexcharts-tooltip-text-value {
  color: #19191a
}
/* .apexcharts-tooltip-text{color:#11ace9} */
/* .apexcharts-tooltip-series-group{color:#11ace9} */
.apexcharts-tooltip-title {
  color: #121313
}
.tooltip-base-box {
  width: 600px;
}
.tooltip-base-box .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip-base-box .center {
  justify-content: center;
}
.tooltip-base-box .box-item {
  width: 110px;
  margin-top: 10px;
}
.el-date-table th {
  background-color: black;
  color: white; /* Cambia el color del texto si es necesario */
}
.el-collapse-item__content {
  color: black !important;
}
</style>
